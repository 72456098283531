<template>
  <form
    @submit.prevent="save"
    @click="clearSuggestions"
    v-if="exercise"
    class="list-group list-group-flush"
    autocomplete="off"
  >
    <!-- NAME -->
    <div class="list-group-item p-3">
      <div class="form-floating position-relative">
        <input
          @focus="selectText"
          @input="findSuggestions"
          v-model.trim="item.name"
          type="text"
          class="form-control"
          id="exercise-name"
          placeholder="Insert exercise name..."
          autocomplete="off"
        >
        <label for="exercise-name">Name</label>
        <div v-if="isLoadingSuggestions" class="spinner">
          <div class="spinner-border spinner-border-sm text-secondary" role="status">
            <span class="visually-hidden">Searching...</span>
          </div>
        </div>
        <InputSuggestion
          @select="selectSuggestion"
          @dismiss="clearSuggestions"
          v-if="suggestions !== null"
          :items="suggestions"
          :slideDirection="'up'"
        ></InputSuggestion>
      </div>
      <div
        v-if="isExercise || isCircuit"
        class="d-flex align-items-baseline mt-2 small justify-content-end"
      >
        <ExerciseLookupConfig v-if="isExercise" class="flex-grow-1 me-3"></ExerciseLookupConfig>
        <a
          @click="showExerciseHistoryModal = true"
          v-if="athleteId && item"
          class="link-primary text-decoration-none"
          role="button"
        >
          <fa-icon :icon="['fas', 'history']"></fa-icon>
          <span class="ms-1 text-decoration-underline">Exercise history</span>
        </a>
      </div>
    </div>

    <!-- METRIC (FOR EXERCISE-TYPE AND CIRCUIT-TYPE) -->
    <div v-if="isExercise || isCircuit" class="list-group-item p-3 small">
      <a
        @click="showMetricModal = true"
        v-if="!hasMetric"
        class="link-primary text-decoration-underline"
        role="button"
      >
        <fa-icon :icon="['fas', 'link']"></fa-icon>
        Link to a metric
      </a>
      <div v-else class="d-flex">
        <div class="flex-grow-1 me-3">
          <fa-icon :icon="['fas', 'link']"></fa-icon>
          Linked to <strong>{{ this.item.metricName_ }} ({{ this.item.metricUnit_ }})</strong>
        </div>

        <a
          @click="showHistory"
          v-if="athleteId"
          class="link-primary text-decoration-none me-3"
          role="button"
        >
          <fa-icon :icon="['fas', 'chart-line']"></fa-icon>
          <span class="ms-1 text-decoration-underline">Metric history</span>
        </a>

        <a
          @click="unlinkMetric"
          class="link-primary text-decoration-none"
          role="button"
        >
          <fa-icon :icon="['fas', 'unlink']"></fa-icon>
          <span class="ms-1 text-decoration-underline">Unlink</span>
        </a>
      </div>
    </div>

    <!-- DESCRIPTION -->
    <div class="list-group-item p-3">
      <div class="form-floating">
        <textarea
          v-model.trim="item.description"
          ref="exerciseDescription"
          id="exercise-description"
          class="form-control"
          placeholder="Insert instructions..."
        ></textarea>
        <label for="exercise-description">Instructions</label>
      </div>
    </div>

    <!-- SETS -->
    <div v-if="isExercise" class="list-group-item p-3">
      <div class="d-flex align-items-center">
        <div class="flex-grow-1 fs-4 fw-light me-3">Sets</div>
        <IconButton
          @click="addSet"
          :icon="'plus'"
          :text="'Add set'"
          class="btn btn-sm btn-primary"
        ></IconButton>
      </div>
      <SetPropagationConfig
        v-if="itemSets.length"
        class="small"
      ></SetPropagationConfig>
      <div v-if="itemSets.length" class="list-group list-group-flush border-top mt-3">
        <div
          v-for="(set, setIndex) in itemSets"
          :key="`key-${setIndex}`"
          class="list-group-item"
        >
          <div class="d-flex align-items-center">
            <div class="font-monospace fw-bold me-3">#{{ setIndex + 1}}</div>
            <div class="input-group">
              <input
                @focus="selectText"
                @input="propagateSetChanges($event, setIndex, 'reps')"
                v-model.trim="set.reps"
                type="text"
                class="form-control form-control-sm"
                placeholder="No of reps..."
              >
              <input
                @focus="selectText"
                @input="propagateSetChanges($event, setIndex, 'load')"
                v-model.trim="set.load"
                type="text"
                class="form-control form-control-sm"
                placeholder="Weight"
              >
              <select
                @input="propagateSetChanges($event, setIndex, 'unit')"
                v-model="set.unit"
                class="form-select form-select-sm"
              >
                <option disabled value="">Choose...</option>
                <option value="kg">kg</option>
                <option value="percent">%</option>
                <option value="rpe">rpe</option>
                <option value="lbs">lbs</option>
                <option value="bodyweight">bodyweight</option>
              </select>
            </div>
            <IconButton
              @click="removeSet(setIndex)"
              :icon="'trash-alt'"
              class="btn btn-sm btn-danger ms-2"
            ></IconButton>
          </div>
        </div>
      </div>
    </div>

    <!-- RESOURCES -->
    <div v-if="!isSection" class="list-group-item p-3">
      <div class="d-flex align-items-center">
        <div class="flex-grow-1 fs-4 fw-light me-3">Videos</div>
        <IconButton
          @click="addResource"
          :icon="'plus'"
          :text="'Add video'"
          class="btn btn-sm btn-primary"
        ></IconButton>
      </div>
      <div v-if="itemResources.length" class="list-group list-group-flush border-top mt-3">
        <div
          v-for="(resource, resourceIndex) in itemResources"
          :key="`key-${resourceIndex}`"
          class="list-group-item d-flex align-items-center"
        >
          <div class="font-monospace fw-bold me-3">#{{ resourceIndex + 1}}</div>
          <div class="input-group">
            <input
              @focus="selectText"
              v-model.trim="resource.url"
              type="text"
              class="form-control form-control-sm"
              placeholder="Insert video url..."
            >
            <IconButton
              @click="previewResource(resourceIndex)"
              v-if="resource.url !== ''"
              :icon="'video'"
              :text="'View'"
              class="btn btn-sm btn-primary"
            ></IconButton>
          </div>
          <IconButton
            @click="deleteResource(resourceIndex)"
            :icon="'trash-alt'"
            class="btn btn-sm btn-danger ms-2"
          ></IconButton>
        </div>
      </div>
      <VideoUrlSample class="mt-3"></VideoUrlSample>
    </div>

    <!-- CTA -->
    <div class="list-group-item p-3 border-bottom-0">
      <SubmitButton
        :text="'Save'"
        :textBusy="'Saving...'"
        :isLoading="isBusy"
        :disabled="isBusy"
        class="btn btn-primary w-100"
      ></SubmitButton>
    </div>

    <portal to="modal">
      <transition name="modal">
        <ResourcePreviewModal
          @close="showPreviewModal = false"
          v-if="showPreviewModal"
          v-model="showPreviewModal"
          :url="previewUrl"
        ></ResourcePreviewModal>
      </transition>
    </portal>

    <portal to="modal">
      <transition name="modal">
        <MetricLinkModal
          @link="linkMetric"
          @close="showMetricModal = false"
          v-if="showMetricModal"
          v-model="showMetricModal"
          :exercise="exercise"
        ></MetricLinkModal>
      </transition>
    </portal>

    <portal to="modal">
      <transition name="modal">
        <MetricHistoryModal
          @close="hideHistory"
          v-model="showMetricHistoryModal"
          v-if="showMetricHistoryModal"
          :metricId="activeMetricId"
          :userId="athleteId"
        ></MetricHistoryModal>
      </transition>
    </portal>

    <portal to="modal">
      <transition name="modal">
        <ExerciseHistoryModal
          @close="showExerciseHistoryModal = false"
          v-if="showExerciseHistoryModal"
          v-model="showExerciseHistoryModal"
          :exercise="item"
          :userId="athleteId"
        ></ExerciseHistoryModal>
      </transition>
    </portal>
  </form>
</template>

<script>
import { mapState } from 'vuex';
import { deepCopy, debounce } from '@/core/util';
import { httpGet } from '@/core/http';
import Factory from '@/core/factory';

export default {
  name: 'SessionEditorItemDetails',
  components: {
    IconButton: () => import('@/components/button/IconButton'),
    SubmitButton: () => import('@/components/button/SubmitButton'),
    MetricLinkModal: () => import('@/components/modal/MetricLinkModal'),
    MetricHistoryModal: () => import('@/components/modal/MetricHistoryModal'),
    ExerciseHistoryModal: () => import('@/components/modal/ExerciseHistoryModal'),
    ResourcePreviewModal: () => import('@/components/modal/ResourcePreviewModal'),
    ExerciseLookupConfig: () => import('@/components/config/ExerciseLookupConfig'),
    SetPropagationConfig: () => import('@/components/config/SetPropagationConfig'),
    InputSuggestion: () => import('@/elements/InputSuggestion'),
    VideoUrlSample: () => import('@/elements/VideoUrlSample'),
  },
  props: {
    session: {
      type: Object,
      required: true,
    },
    exercise: {
      type: Object,
      required: true,
    },
    athleteId: {
      type: String,
      default: null,
    },
  },
  watch: {
    session() {
      this.isBusy = false;
      this.isLoadingSuggestions = false;
    },
  },
  computed: {
    ...mapState('auth', ['authUser']),
    ...mapState('config', ['configUISetPropagation', 'configUIEditorExerciseLookup']),
    isSection() {
      return this.exercise.type === 'section';
    },
    isExercise() {
      return this.exercise.type === 'exercise';
    },
    isCircuit() {
      return this.exercise.type === 'circuit';
    },
    hasMetric() {
      return this.item.metricId !== null;
    },
  },
  methods: {
    selectText(e) {
      e.target.select();
    },
    save() {
      // Sanitise sets, remove empty ones.
      this.itemSets = this.itemSets.filter((s) => s.reps.trim() !== '' || s.load.trim() !== '');

      // Sanitise resources, remove empty ones.
      this.itemResources = this.itemResources.filter((r) => r.url.trim() !== '');

      const {
        sessionId,
        exerciseId,
        metricId,
        name,
        description,
      } = this.item;
      const payload = {
        sessionId,
        exerciseId,
        metricId,
        name,
        description,
        exerciseSet: JSON.stringify(this.itemSets),
        resource: JSON.stringify(this.itemResources),
      };

      this.isBusy = true;
      this.$emit('sessionEditorItemDetailsUpdate', payload);
    },

    /* LIBRARY (EXERCISE AND CIRCUIT) */

    clearSuggestions() {
      this.suggestions = null;
    },
    findSuggestions(e) {
      this.clearSuggestions();
      const { value } = e.target;
      if (value.trim().length > 1) {
        this.debouceLookup();
      }
    },
    selectSuggestion(item) {
      const { name, description } = item;
      this.item.name = name;
      this.item.description = description;
      if (this.isExercise) {
        const { videoUrl } = item;
        if (videoUrl && videoUrl !== '') {
          this.itemResources = [{
            url: videoUrl,
          }];
        } else {
          this.itemResources = [];
        }
      } else if (this.isCircuit) {
        this.itemResources = this.$options.filters.exerciseResourceParsed(item);
      }
      this.clearSuggestions();
    },
    async loadSuggestions() {
      if (this.isSection) return;
      this.isLoadingSuggestions = true;
      try {
        const { userId } = this.authUser;
        const { name } = this.item;
        if (this.isExercise) {
          const res = await httpGet('/library', {
            userId,
            keyword: name,
            skipPublicLibrary: this.configUIEditorExerciseLookup,
          });
          this.suggestions = res.data;
        } else if (this.isCircuit) {
          const res = await httpGet('/library/circuit', {
            userId,
            keyword: name,
          });
          this.suggestions = res.data;
        }
      } catch (err) {
        this.$store.dispatch('addSystemError', err, { root: true });
      } finally {
        this.isLoadingSuggestions = false;
      }
    },

    /* SETS */

    addSet() {
      const set = Factory.createExerciseSet();
      this.itemSets.push(set);
    },
    removeSet(index) {
      this.itemSets.splice(index, 1);
    },
    propagateSetChanges(e, index, attr) {
      if (this.configUISetPropagation === 'false') {
        return;
      }
      const { value } = e.target;
      const { itemSets } = this;
      if (itemSets) {
        itemSets.forEach((_, setIndex) => {
          if (setIndex >= index) {
            itemSets[setIndex][attr] = value;
          }
        });
      }
    },

    /* RESOURCES */

    addResource() {
      const resource = Factory.createExerciseResource();
      this.itemResources.push(resource);
    },
    deleteResource(index) {
      this.itemResources.splice(index, 1);
    },
    previewResource(index) {
      this.showPreviewModal = false;
      const resource = this.itemResources[index];
      if (resource && resource.url && resource.url !== '') {
        this.previewUrl = resource.url;
        this.showPreviewModal = true;
      }
    },

    /* METRIC */

    linkMetric(metric) {
      const { metricId, name, unit } = metric;
      this.item.metricId = metricId;
      this.item.metricName_ = name;
      this.item.metricUnit_ = unit;
    },
    unlinkMetric() {
      this.item.metricId = null;
    },
    showHistory() {
      const { metricId } = this.item;
      if (metricId) {
        this.activeMetricId = metricId;
        this.showMetricHistoryModal = true;
      }
    },
    hideHistory() {
      this.showMetricHistoryModal = false;
      this.activeMetricId = null;
    },
  },
  data() {
    return {
      isBusy: false,
      isLoadingSuggestions: false,
      showPreviewModal: false,
      showMetricModal: false,
      showMetricHistoryModal: false,
      showExerciseHistoryModal: false,
      previewUrl: null,
      item: null,
      itemSets: null,
      itemResources: null,
      suggestions: null,
      activeMetricId: null,
    };
  },
  created() {
    if (this.exercise) {
      this.item = deepCopy(this.exercise);
      this.itemSets = this.$options.filters.exerciseSetParsed(this.item);
      this.itemResources = this.$options.filters.exerciseResourceParsed(this.item);

      // Register a debounced command to lookup exercise/circuit in the library.
      this.debouceLookup = debounce(() => this.loadSuggestions(), 250);
    }
  },
};
</script>

<style lang="scss" scoped>
.spinner {
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  pointer-events: none;
}
</style>
